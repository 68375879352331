@import "helpers/variables";

.full-width, .w-100 {
    width: 100%;
}

.padded {
    padding: 20px;
}

.padded-side {
    padding: 0 20px 0 20px;    
}

.no-pad {
    padding: 0 !important;
}

.isolated {
    margin: 20px 0 40px 0;   
}

.view-content {
    padding: 30px 20px 4em 20px;
}


#ol-main-content-container {
    overflow-x: hidden;
    position: relative;
    z-index: 0;
    background-color: #e5ecf0;
}

#ol-main-content {
    min-height: calc(100vh - 150px);
}

.center-text {
    text-align: center;
}

.mouse-pointer {
    cursor: pointer;
}

.divider-margin {
    margin: 0 -20px 0 -20px;
}
