[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none !important; }

.ol-main-content button.md-fab {
  transition: all 500ms cubic-bezier(0.62, 0.28, 0.23, 0.99);
  position: relative;
  z-index: 1; }
  .ol-main-content button.md-fab.inactive, .ol-main-content button.md-fab.loading {
    background-color: #e5e5e5 !important; }
  .ol-main-content button.md-fab.loading {
    transform: scale(0); }
  .ol-main-content button.md-fab[disabled] {
    background-color: #e5e5e5 !important; }
  .ol-main-content button.md-fab md-icon {
    height: 26px; }

#ol-main-content-container #main-error-container md-dialog {
  width: 30%; }

#ol-main-content-container .floating-title {
  position: absolute;
  text-align: left;
  width: 100%;
  left: 10px;
  font-size: 20vw;
  opacity: 0.1;
  color: #555;
  text-transform: uppercase;
  z-index: 0;
  line-height: 250px; }
  #ol-main-content-container .floating-title.small {
    font-size: 10em; }

#ol-main-content-container .ctas {
  width: 100%;
  max-height: 100px; }
  #ol-main-content-container .ctas .md-button {
    transition: all 500ms cubic-bezier(0.62, 0.28, 0.23, 0.99);
    font-size: 2vw;
    background: none;
    border-radius: 0;
    color: #F44336;
    border: 7px solid #F44336;
    padding: 2vw auto auto auto;
    min-height: 80px; }
    #ol-main-content-container .ctas .md-button:hover {
      color: #E57373;
      border-color: #E57373; }

#ol-main-content-container .philosophy-row {
  margin: 10px 0 10px 0; }

#ol-main-content-container .breaker-image {
  width: 100%;
  height: 500px;
  object-fit: cover; }

@media (max-width: 960px) {
  #ol-main-content-container .ctas .md-button {
    font-size: 24pt; } }

html {
  margin: 0;
  padding: 0; }

body {
  min-width: 400px; }

a {
  color: #F44336;
  text-decoration: none; }

.group:after {
  content: "";
  display: table;
  clear: both; }

md-card.rest-grey {
  background-color: #fafafa; }

md-card.card-large {
  transition: all ease-in-out 500ms;
  transition-property: box-shadow, background-color; }

md-card.card-small {
  transition: all ease-in-out 100ms;
  transition-property: box-shadow, background-color; }

md-card:hover {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff; }

[ui-view] {
  transition: all 500ms cubic-bezier(0.62, 0.28, 0.23, 0.99); }
  [ui-view].ng-enter-prepare {
    opacity: 0; }
  [ui-view].ng-enter {
    opacity: 0; }
    [ui-view].ng-enter.ng-enter-active {
      opacity: 1; }
  [ui-view].ng-leave {
    opacity: 1; }
    [ui-view].ng-leave.ng-leave-active {
      opacity: 0; }
  [ui-view] .no-results {
    padding: 30px;
    min-height: 300px;
    font-size: 1.1em; }

.switch-frame > [ng-switch-when] {
  transition: all 500ms cubic-bezier(0.62, 0.28, 0.23, 0.99); }
  .switch-frame > [ng-switch-when].ng-enter, .switch-frame > [ng-switch-when].ng-leave.ng-leave-active {
    height: 0; }
  .switch-frame > [ng-switch-when].ng-enter.ng-enter-active, .switch-frame > [ng-switch-when].ng-leave {
    height: 300px; }

.slide-container {
  transition: all 500ms cubic-bezier(0.62, 0.28, 0.23, 0.99);
  height: auto; }
  .slide-container.ng-hide {
    opacity: 0;
    height: 0; }

g,
rect,
path {
  transition: all 500ms cubic-bezier(0.62, 0.28, 0.23, 0.99); }

body {
  font-family: 'Montserrat', sans-serif;
  color: #212121; }

p {
  font-family: 'Cardo', serif;
  font-size: 1.4em; }

h1 {
  font-family: 'Montserrat';
  font-size: 3.8em; }

h2 {
  font-size: 2.8em;
  font-family: 'Montserrat', serif; }

h3 {
  font-size: 1.8em;
  font-family: 'Montserrat', serif; }

h4 {
  font-size: 1.3em; }

.full-width, .w-100 {
  width: 100%; }

.padded {
  padding: 20px; }

.padded-side {
  padding: 0 20px 0 20px; }

.no-pad {
  padding: 0 !important; }

.isolated {
  margin: 20px 0 40px 0; }

.view-content {
  padding: 30px 20px 4em 20px; }

#ol-main-content-container {
  overflow-x: hidden;
  position: relative;
  z-index: 0;
  background-color: #e5ecf0; }

#ol-main-content {
  min-height: calc(100vh - 150px); }

.center-text {
  text-align: center; }

.mouse-pointer {
  cursor: pointer; }

.divider-margin {
  margin: 0 -20px 0 -20px; }

footer {
  background-color: #222326;
  color: #ffffff;
  min-height: 150px;
  width: 100%;
  padding: 50px 0 50px 0; }
  footer ul li {
    list-style: none;
    padding-bottom: 15px; }
    footer ul li i {
      margin-right: 20px; }
    footer ul li a {
      margin-left: 20px; }

.version-footer {
  background-color: #eeeeee;
  color: #AAAAAA;
  font-size: 0.8em;
  padding: 3px;
  text-align: center;
  position: fixed;
  bottom: 0;
  z-index: 79;
  width: 80px;
  border-radius: 0 20px 0 0; }

#header {
  transition: all 500ms cubic-bezier(0.62, 0.28, 0.23, 0.99);
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 170px; }
  #header #logo-image {
    transition: all 500ms cubic-bezier(0.62, 0.28, 0.23, 0.99);
    margin: 30px 0 0 30px;
    width: 150px;
    filter: brightness(0%) invert(1); }
  #header.collapsed {
    background-color: white;
    height: 150px; }
    #header.collapsed #logo-image {
      margin: -10px 0 30px 50px;
      width: 100px;
      filter: brightness(100%); }

@media (max-width: 960px) {
  #header.collapsed {
    height: 180px; }
    #header.collapsed #logo-image {
      margin: 15px; } }

nav {
  margin: 20px 0px 20px 40px; }
  nav a {
    transition: all 500ms cubic-bezier(0.62, 0.28, 0.23, 0.99);
    color: #1A237E;
    font-size: 1.5vw;
    text-transform: uppercase;
    text-align: center; }
    nav a:hover {
      color: #F44336;
      margin-top: -7px; }
    nav a.selected {
      color: #D32F2F; }

@media (max-width: 960px) {
  nav {
    padding: 20px 0 20px 0;
    background-color: #e5ecf0;
    width: 100%;
    margin: 350px 0 0 0;
    position: absolute; }
    nav a {
      font-size: 14pt;
      color: #1A237E; }
      nav a:hover {
        color: #F44336; }
  .collapsed nav {
    margin: 120px 0 0 0;
    background-color: transparent; }
    .collapsed nav a {
      color: #1A237E; }
      .collapsed nav a:hover {
        color: #F44336; } }

@media (max-width: 500px) {
  nav {
    padding: 10px 0 10px 0;
    margin: 370px 0 0 0; }
    nav a {
      font-size: 11pt; } }

#home #hero-image {
  height: 850px;
  width: 100%;
  background-image: url("/images/grit-talent-consulting-office-workers.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: -200px; }

#home .philosophy-row {
  margin: 10px 0 10px 0; }
  #home .philosophy-row .philosophy-item {
    padding: 15px; }

#home section.light {
  padding: 100px 0 100px 0;
  background-color: white; }
  #home section.light h4 {
    color: #F44336; }

#home section.hero {
  height: 850px; }
  #home section.hero h1, #home section.hero h2 {
    -webkit-margin-before: 5px;
    -webkit-margin-after: 0px;
    color: #1A237E; }
  #home section.hero h1 {
    font-size: 4vw;
    line-height: 5vw;
    padding-bottom: 50px; }
  #home section.hero h2 {
    font-size: 2.5vw;
    line-height: 3.5vw; }
  #home section.hero .section-body {
    padding-top: 150px; }

#home section .section-body {
  padding: 0 40px; }

@media (max-width: 960px) {
  #home .hero-image-container {
    display: block;
    max-height: 400px; }
  #home #hero-image {
    width: 100%;
    height: 400px;
    background-position-x: 0; }
  #home section.hero {
    height: 1300px; }
    #home section.hero .section-body {
      padding-top: 30px; }
    #home section.hero h1 {
      font-size: 45px;
      line-height: 60px; }
    #home section.hero h2 {
      font-size: 24px;
      line-height: 38px; }
  #home section.light {
    padding-top: 0px; } }

@media (min-width: 1400px) {
  #home section.hero h1 {
    font-size: 55px;
    line-height: 70px; }
  #home section.hero h2 {
    font-size: 34px;
    line-height: 48px; } }

#services .hero-image {
  top: 150px;
  height: 500px;
  width: 100%;
  background-image: url("/images/grit-recruitment-services.jpg");
  background-size: cover; }

#services section.light {
  padding: 60px 0 100px 0; }
  #services section.light .service-block {
    margin-bottom: 80px; }

#candidates .hero-image {
  top: 150px;
  height: 500px;
  width: 100%;
  background-image: url("/images/grit-candidates.jpg");
  background-size: cover;
  background-position: 0 70%; }

#candidates section.light {
  padding: 60px 0 100px 0; }
  #candidates section.light .service-block {
    margin-bottom: 80px; }

#contact .hero-image {
  top: 150px;
  height: 500px;
  width: 100%;
  background-image: url("/images/grit-contact-us.jpg");
  background-size: cover;
  background-position: 0 70%; }

#contact section.light {
  padding: 60px 0 100px 0; }
  #contact section.light md-input-container {
    font-size: 2em; }
  #contact section.light .service-block {
    margin-bottom: 80px; }
