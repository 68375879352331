@import "helpers/variables";

footer {
    background-color: #222326;
    color: #ffffff;
    min-height: 150px;
    width: 100%;
    padding: 50px 0 50px 0;

    ul {
        li {
            list-style: none;
            padding-bottom: 15px;

            i {
                margin-right: 20px;
            }

            a {
                margin-left: 20px;
            }
        }
    }
}

 .version-footer {
    background-color: $color-grey-light;
    color: $tertiary-dark;
    font-size: 0.8em;
    padding: 3px;
    text-align: center;
    position: fixed;
    bottom: 0;
    z-index: 79;
    width: 80px;
    border-radius: 0 20px 0 0;
}