@import "helpers/variables";

nav {
    margin: 20px 0px 20px 40px;

    a {
        transition: $swift-ease-in-out;
        color: $color-accent;
        font-size: 1.5vw;
        text-transform: uppercase;
        text-align: center;
        
        &:hover {
            color: $primary-500;
            margin-top: -7px;
        }

        &.selected {
             color: $primary-700;
        }
    }
}

@media (max-width: 960px) {
    nav {
        padding: 20px 0 20px 0;
        background-color: #e5ecf0;
        width: 100%;
        margin: 350px 0 0 0;
        position: absolute;
    
        a {
            font-size: 14pt;
            color: $color-accent;
            
            &:hover {
                color: $primary-500;
            }
        }
    }

    &.collapsed { 
        nav {
            margin: 120px 0 0 0;
            background-color: transparent;

            a {
                color: $color-accent;
                &:hover {
                    color: $primary-500;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    nav {
        padding: 10px 0 10px 0;
        margin: 370px 0 0 0;

        a {
            font-size: 11pt;
        }
    }
}
