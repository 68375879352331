@import "helpers/variables";
@import "helpers/mixins";

md-card {

    &.rest-grey {
        background-color: #fafafa;
    }

    &.card-large {
        transition: all ease-in-out $easing-weight-500;
        transition-property: box-shadow, background-color;
    }

    &.card-small {
        transition: all ease-in-out $easing-weight-100;
        transition-property: box-shadow, background-color;
    }

    &:hover {
        box-shadow: $whiteframe-shadow-8dp;
        background-color: #fff;
    }
}

// Specific Elements
[ui-view] {
    @include reveal($swift-ease-in-out);

    .no-results {
        padding: 30px;
        min-height: 300px;
        font-size: 1.1em;
    }
}

.switch-frame > [ng-switch-when] {
    transition: $swift-ease-in-out;
    &.ng-enter,
    &.ng-leave.ng-leave-active {
        height: 0;
    }
    &.ng-enter.ng-enter-active,
    &.ng-leave {
        height: 300px;
    }
}

.slide-container {
    transition: $swift-ease-in-out;
    height: auto;
    &.ng-hide {
        opacity: 0;
        height: 0;
    }
}

g,
rect,
path {
    transition: $swift-ease-in-out;
}