@import "helpers/variables";

html {
    margin: 0;
    padding: 0;
}

body {
    min-width: 400px; 
}

a {
    color: $primary-500;
    text-decoration: none;
}

.group:after {
  content: "";
  display: table;
  clear: both;
}