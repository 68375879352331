@import "helpers/variables";
@import "helpers/mixins";

#ol-main-content-container {

    #main-error-container {
        md-dialog {
            width: 30%;
        }
    } 

    .floating-title {
        position: absolute;
        text-align: left;
        width: 100%;
        left: 10px;
        font-size: 20vw;
        opacity: 0.1;
        color: #555;
        text-transform: uppercase;
        z-index: 0;
        line-height: 250px;

        &.small {
            font-size: 10em;
        }
    }

    .ctas {
        width: 100%;
        max-height: 100px;

        .md-button {
            transition: $swift-ease-in-out;
            font-size: 2vw;
            background: none;
            border-radius: 0;
            color: $primary-500;
            border: 7px solid $primary-500;
            padding: 2vw auto auto auto;
            min-height: 80px;

            &:hover {
                color: $primary-300;
                border-color: $primary-300;
            }
        }
    }

    .philosophy-row {
        margin: 10px 0 10px 0;
    }

    .breaker-image {
        width: 100%;
        height: 500px;
        object-fit: cover;
    }
}

@media (max-width: 960px) {
    #ol-main-content-container {
        .ctas {
            .md-button {
                font-size: 24pt;
            }
        }
    }
}
