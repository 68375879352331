// Typography rules

body {
    font-family: 'Montserrat', sans-serif;
    color: $primary-dark;
}

p {
     font-family: 'Cardo', serif;
    font-size: 1.4em;
}

h1 {
    font-family: 'Montserrat';
    font-size: 3.8em;
}

h2 {
    font-size: 2.8em;
    font-family: 'Montserrat', serif;
}

h3 {
    font-size: 1.8em;
    font-family: 'Montserrat', serif;
}

h4 {
    font-size: 1.3em;
}


