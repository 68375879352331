// Sass Mixins 
@mixin reveal($easing) {
    transition: $easing;

    &.ng-enter-prepare {
        opacity: 0;
    }

    &.ng-enter {
        opacity: 0;

        &.ng-enter-active {
            opacity: 1;
        }
    }

    &.ng-leave {
        opacity: 1;

        &.ng-leave-active {
            opacity: 0;
        }
    }
}

@mixin fade($easing) {
    transition: $easing;

    &.ng-hide {
        opacity: 0;
    }
}

@mixin pop($easing) {
    transition: $easing;
    opacity: 1;
    transform: scale(1);

    &.ng-hide {
        opacity: 0;
        transform: scale(0.7);
    }
}

@mixin delay-children($limit, $increment) {
    
    @if $limit > 0 and $increment > 0 {
        @for $count from 1 through $limit {
            &:nth-child(#{$count}) { 
                transition-delay: #{$count * $increment}s; 
            }
        }
    } 
}
