#services {
    .hero-image {
        top: 150px;
        height: 500px;
        width: 100%;
        background-image: url('/images/grit-recruitment-services.jpg');
        background-size: cover;
    } 

    section.light {
        padding: 60px 0 100px 0;

        .service-block {
            margin-bottom: 80px;
        }
    }
}