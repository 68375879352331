@import "helpers/variables";

.ol-main-content button.md-fab {
    transition: $swift-ease-in-out;
    position: relative;
    z-index: 1;
    
    &.inactive, &.loading {
        background-color: $color-grey-middle !important;
    }

    &.loading {
        transform: scale(0);
    }
    
    &[disabled] {
        background-color: $color-grey-middle !important;
    }
    
    md-icon {
        height: 26px;    
    } 
}