@import "helpers/variables";

#header {
    transition: $swift-ease-in-out;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 170px; 

    #logo-image {
        transition: $swift-ease-in-out;
        margin: 30px 0 0 30px;
        width: 150px;
        filter: brightness(0%) invert(1);
    } 

    &.collapsed {
        background-color: white;
        height: 150px;

        #logo-image {
            margin: -10px 0 30px 50px;
            width: 100px;
            filter: brightness(100%);
        }
    }
}

@media (max-width: 960px) {
    #header {
        &.collapsed {
            height: 180px;

            #logo-image {
                margin: 15px;
            }
        }
    }
}