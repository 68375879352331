#home {
    #hero-image {
        height: 850px;
        width: 100%;
        background-image: url('/images/grit-talent-consulting-office-workers.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: -200px;
    }

    .philosophy-row {
        margin: 10px 0 10px 0;

        .philosophy-item {
            padding: 15px;
        }
    }

    section {

        &.light {
            padding: 100px 0 100px 0;
            background-color: white;
            
            h4 {
                color: $primary-500;
            }
        }

        &.hero {
            height: 850px;

            h1, h2 {
                -webkit-margin-before: 5px;
                -webkit-margin-after: 0px;
                color: $color-accent;
            }

            h1 {
                font-size: 4vw;
                line-height: 5vw;
                padding-bottom: 50px;
            }

            h2 {
                font-size: 2.5vw;
                line-height: 3.5vw;
            }

            .section-body {
                padding-top: 150px;
            }
        }

        .section-body {
            padding: 0 40px;
        }
    }
}

@media (max-width: 960px) {
    #home {
        .hero-image-container {
            display: block;
            max-height: 400px;
        }

        #hero-image {
            width: 100%;
            height: 400px;
            background-position-x: 0;
        }

        section {
            
            &.hero {
                height: 1300px;

                .section-body {
                    padding-top: 30px;
                }

                h1 { 
                    font-size: 45px;
                    line-height: 60px;
                }

                h2 {
                    font-size: 24px;
                    line-height: 38px;
                }
            }

            &.light {
                padding-top: 0px;
            }
        }
    }
}

@media (min-width: 1400px) {
    #home {
        section.hero {

            h1 { 
                font-size: 55px;
                line-height: 70px;
            }

            h2 {
                font-size: 34px;
                line-height: 48px;
            }
        }
    }
}